import { format } from 'date-fns'
import { graphql, Link, navigate } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import type { FC } from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { Divider, HTMLContent } from '../components/Overrides'
import { SEO } from '../components/Seo'
import { isOpenSource } from '../constants/config'
import { tags } from '../constants/routes'
import { defaultBranchPath } from '../constants/strings'
import { rhythm, scale } from '../helpers/typography'
import { useKeyPress } from '../hooks/useKeyPress'

import type { ContentProps } from './types'

const Note: FC<ContentProps> = ({ data, pageContext }) => {
  const { markdownRemark: note, file, site } = data
  const { previous, next } = pageContext

  useKeyPress('arrowleft', () => {
    if (previous) {
      navigate(previous.fields.slug)
    }
  })

  useKeyPress('arrowright', () => {
    if (next) {
      navigate(next.fields.slug)
    }
  })

  return (
    <Layout>
      <SEO
        description={note.excerpt}
        keywords={note.frontmatter.tags ?? []}
        modifiedTime={note.fields.lastEdited}
        pathname={note.fields.slug}
        publishedTime={note.frontmatter.date}
        title={note.frontmatter.title}
        type="article"
      />

      {file && <Hero Tag="section" fluid={file.childImageSharp.fluid} />}

      <h1
        style={{
          ...scale(1.4),
        }}
      >
        {note.frontmatter.title}
      </h1>

      <p
        style={{
          ...scale(-1 / 5),
          marginBottom: rhythm(1),
          marginTop: rhythm(-0.2),
        }}
      >
        <time dateTime={format(new Date(note.frontmatter.date), 'yyyy-MM-dd')}>
          {note.frontmatter.date}
        </time>{' '}
        {note.fields.lastEdited !== note.frontmatter.date && (
          <em>(Last edited: {note.fields.lastEdited})</em>
        )}
      </p>

      <HTMLContent dangerouslySetInnerHTML={{ __html: note.html }} />

      <Divider
        style={{
          marginBottom: rhythm(1),
        }}
      />

      {note.frontmatter.tags && (
        <ul>
          {note.frontmatter.tags.map((tag, ii) => (
            <li key={ii}>
              <Link to={`${tags}/${tag}`}>{tag}</Link>
            </li>
          ))}
        </ul>
      )}

      {isOpenSource && (
        <p>
          <a
            href={`${site.siteMetadata.sourceUrl}${defaultBranchPath}${note.fields.path}`}
            rel="noopener noreferrer"
          >
            Suggest a change
          </a>
        </p>
      )}

      <PaginationBox>
        <div>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </div>
        <div>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </div>
      </PaginationBox>
    </Layout>
  )
}

// eslint-disable-next-line import/no-default-export
export default Note

const Hero = styled(BackgroundImage)`
  height: 300px;
`

const PaginationBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

// NOTE: cannot use the `useStaticQuery` hook because of the way Gatsby uses a technique from Relay
// NOTE: cannot use the `useStaticQuery` hook because of this: https://www.gatsbyjs.org/docs/page-query/#the-longer-answer
// NOTE: used by gatsby-node.js to inject data at build time
export const pageQuery = graphql`
  query NoteBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      fields {
        lastEdited(formatString: "MMMM DD, YYYY")
        path
        slug
      }
      frontmatter {
        title
        contentType
        date(formatString: "MMMM DD, YYYY")
        tags
      }
      html
    }
    file(dir: { regex: $slug }, name: { regex: "/hero/" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2048) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        sourceUrl
      }
    }
  }
`
